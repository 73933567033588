import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { get } from 'lodash'
import { DateTime } from 'luxon'
import { Controller, FieldValues } from 'react-hook-form'
import { useThemeContext } from '../../../../providers/hooks/useThemeContext'
import { applyTimeZoneToDateTime, getCurrentTime, getTimeFromIso, ZONE } from '../../../../utils/date'
import { useFormContext } from '../../hooks/useFormContext'
import { InputDateProps } from './types'

const InputDate = <T extends FieldValues>({
  fieldName,
  required,
  label,
  minTime: minTimeProp,
}: InputDateProps<T>) => {
  const theme = useThemeContext()

  const {
    control,
    formState: { errors },
  } = useFormContext<T>()

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ required }}
      render={({ field: { onChange, value } }) => {
        const convertDate = (date: string | number | object) => {
          if (typeof date === 'number') {
            const date = DateTime.fromSeconds(value)
            return applyTimeZoneToDateTime(date)
          }
          if (typeof date === 'object') return date
          return getTimeFromIso(value)
        }

        const d = convertDate(value)
        const now = getCurrentTime()

        const isToday = d instanceof DateTime && d.hasSame(now, 'day')
        const roundedMinutes = Math.ceil((now.minute + 1) / 10) * 10
        const defaultMinTime = now.set({ minute: roundedMinutes, second: 0, millisecond: 0 })

        const minTimePropDate = minTimeProp && convertDate(minTimeProp)

        const minTime =
          minTimePropDate && minTimePropDate instanceof DateTime
            ? minTimePropDate.plus({ minutes: 5 }).set({ second: 0, millisecond: 0 })
            : isToday
              ? defaultMinTime
              : undefined

        return (
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="it">
            <DateTimePicker
              disablePast={true}
              value={d ? d : undefined}
              onChange={onChange}
              autoFocus={true}
              label={label}
              ampmInClock={false}
              format="dd/MM/yyyy - HH:mm"
              minDate={now}
              minTime={minTime}
              minutesStep={5}
              timezone={ZONE}
              selectedSections={'hours'}
              ampm={false}
              slotProps={{
                textField: {
                  InputLabelProps: {
                    required: required,
                    sx: {
                      background: theme.palette.background.paper,
                      paddingInline: '16px',
                      borderRadius: '16px',
                      color: theme.palette.primary.light,
                    },
                  },
                  InputProps: {
                    sx: {
                      background: theme.palette.background.paper,
                    },
                  },
                  error: !!get(errors, fieldName),
                  sx: () => ({
                    borderRadius: '12px',
                    boxShadow: theme.shadows[4],
                    svg: {
                      fill: get(errors, fieldName) ? theme.palette.error.main : 'currentColor',
                    },
                  }),
                },
              }}
            />
          </LocalizationProvider>
        )
      }}
    />
  )
}

export default InputDate
