import { createContext, FC, useCallback } from 'react'
import useWebSocket from 'react-use-websocket'
import { getSoketNotificationByCode } from '../data/notification/socket'
import { useActions } from '../hooks/useActions'
import { setPendingOrder, setVisibleModal } from '../services/state'
import { SocketCode } from '../types/socket'
import { getAccessToken } from '../utils/auth'
import { useNotificationContext } from './hooks'
import { SocketContextData, SocketProviderProps } from './types'

const initialContextData: SocketContextData = {
  socket: undefined,
}

export const SocketContext = createContext<SocketContextData>(initialContextData)

const globalEvents: SocketCode[] = ['auction_ended_won', 'auction_ended_lost']

const useAuctionEndedHandler = () => {
  const actions = useActions({ setPendingOrder, setVisibleModal })
  const wonHandler = useCallback(
    ({ carId }: any) => {
      actions.setPendingOrder([carId])
      actions.setVisibleModal('wonAuction')
    },
    [actions]
  )

  const lostHandler = useCallback(() => {
    actions.setVisibleModal('lostAuction')
  }, [actions])

  return {
    auctionEndedWonHandler: wonHandler,
    auctionEndedLostHandler: lostHandler,
  }
}

export const SocketProvider: FC<SocketProviderProps> = ({ children, url }) => {
  const token = getAccessToken()
  const { setNotificationItems } = useNotificationContext()
  const { auctionEndedWonHandler, auctionEndedLostHandler } = useAuctionEndedHandler()

  const connectionUrl = `${url}?token=${token}`

  const socket = useWebSocket(connectionUrl, {
    share: true,
    onOpen: () => console.log('Connected to the server'),
    onMessage: event => {
      const { code, data } = JSON.parse(event.data)
      const notification = getSoketNotificationByCode(code)

      notification && setNotificationItems([notification])

      if (globalEvents.includes(code)) {
        if (code === 'auction_ended_won') {
          auctionEndedWonHandler(data)
        }

        if (code === 'auction_ended_lost') {
          auctionEndedLostHandler()
        }
      }
    },
    onClose: () => console.log('Disconnected from the server'),
    onError: error => console.error('WebSocket error:', error),
    reconnectInterval: 2000, // Intervallo tra i tentativi di riconnessione (in millisecondi)
    shouldReconnect: closeEvent => {
      // Riconnessione automatica, a meno che il codice di chiusura sia 1000 (chiusura normale)
      return closeEvent.code !== 1000
    },
    reconnectAttempts: undefined,
    retryOnError: true, // Riconnetti automaticamente in caso di errore
    // heartbeat: {
    //   message: 'ping',
    //   returnMessage: 'pong',
    //   timeout: 15000,
    //   interval: 300000,
    // },
  })

  return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>
}
