import { FC } from 'react'
import { LinkGoTo, SignInLayoutPage } from '../../pages/private/signIn'
import { SignInFormButtonContainer } from '../../pages/private/signIn/style'
import { useTranslation } from '../../providers/hooks/i18n'
import { PrimaryButton, SecondaryButton } from '../Button'
import Divider from '../Divider'
import useFormBuilder from '../Form'
import FormContainer from '../Form/FormContainer'
import { FormCompleteHandler } from '../Form/FormContainer/type'
import { Headline } from '../Typography'

export type LoginFormSignInType = {
  email: string
  password: string
}

interface LoginFormSignInProps {
  onSubmit: FormCompleteHandler<LoginFormSignInType>
  createAccountPath: string
  className?: string
}

export const LoginFormSignIn: FC<LoginFormSignInProps> = ({ className, onSubmit, createAccountPath }) => {
  const { InputText } = useFormBuilder<LoginFormSignInType>()
  const { t } = useTranslation()

  return (
    <SignInLayoutPage className={className}>
      <Headline color="secondary" textTransform={'capitalize'}>
        {t('core.greetings')}
      </Headline>
      <FormContainer<LoginFormSignInType> onSubmit={onSubmit} id="signIn">
        <InputText label={t('form.email')} fieldName="email" type="email" required />
        <InputText type="password" label={t('form.password')} fieldName="password" required />
        <PrimaryButton type="submit" color="secondary" fullWidth={false}>
          {t('core.login')}
        </PrimaryButton>
      </FormContainer>

      <LinkGoTo
        to="/forgotPassword"
        alignment="center"
        underlined={true}
        color="primaryLight"
        label={t('login.forgotPassword')}
      />
      <Divider text={t('core.otherOption')} />
      <SignInFormButtonContainer>
        <SecondaryButton color="secondary" navigateTo={createAccountPath}>
          {t('register.createAccount')}
        </SecondaryButton>
      </SignInFormButtonContainer>
    </SignInLayoutPage>
  )
}
