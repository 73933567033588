import { FieldValues } from 'react-hook-form'
import Radio from '../Radio'
import { ChipContainer } from './styles'
import { ChipProps } from './types'

const Chip = <T extends FieldValues>({ isMultivalue = false, ...props }: ChipProps<T>) => {
  const Widget = () => (!isMultivalue ? <Radio {...props} hideControl /> : <></>)

  return (
    <ChipContainer>
      <Widget />
    </ChipContainer>
  )
}

export default Chip
