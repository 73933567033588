import { TextField } from '@mui/material'
import SortableList from 'react-easy-sort'
import styled from 'styled-components'
import { pxToRem } from '../../../../utils/styling'
import { DeleteIcon } from '../../../Icons/Delete'
import { Body } from '../../../Typography'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
`

export const SortableListWrapper = styled(SortableList)`
  user-select: none;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 24px;
`

export const PreviewImage = styled.div<{ src: string }>`
  background-color: ${({ theme }) => theme.palette.common.white};
  background-image: ${({ src }) => `url(${src})`};
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  position: relative;
  user-select: none;

  &.dragged {
    box-shadow: ${({ theme }) => theme.shadows[8]};
    border: 3px solid ${({ theme }) => theme.palette.secondary.light};
  }
`
export const DeletePreviewIconWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: ${pxToRem(36)};
  height: ${pxToRem(36)};
  background-color: white;
  border-radius: 100%;
  padding: 8px;
  isolation: isolate;
  box-shadow: ${({ theme }) => theme.shadows[8]};
`

export const DeletePreviewIcon = styled(DeleteIcon)`
  z-index: 1;
  cursor: pointer;
`

export const TextFieldFileStyled = styled(TextField)`
  user-select: none;
  position: absolute;
  inset: 0;
  margin: 0;
  height: 100%;
`

export const BodyStyled = styled(Body)`
  padding: ${pxToRem(24)} 0;
`

export const ImageUploaderWrapper = styled.div<{ hasError?: boolean }>`
  background: #fff9ea;
  height: 100%;
  min-height: 50vh;
  max-height: 400px;
  overflow: hidden;
  border-radius: ${pxToRem(8)};
  position: relative;
  input {
    min-width: auto;
    height: 100%;
    background: transparent;
    box-shadow: none;
    margin: 0;
    filter: alpha(opacity=0);
    border-color: transparent;
    opacity: 0;
    cursor: pointer;
    position: relative;
  }

  fieldset {
    border: none;
  }
`
