import { TextField } from '@mui/material'
import { get } from 'lodash'
import { Controller, FieldValues } from 'react-hook-form'
import { useFormContext } from '../../hooks/useFormContext'
import { useGetInputProps } from '../../hooks/useGetInputProps'
import { FormFieldValue } from '../types'
import { InputTextProps } from './types'

const InputText = <T extends FieldValues>({
  fieldName,
  required,
  type,
  label,
  helperText,
  multiline,
  multilineRows,
  maxLength,
  minLength,
  disabled,
  onFocus,
  startAdornment,
  endAdornment,
  background,
  defaultValue,
  shrink,
  className,
}: InputTextProps<T>) => {
  const context = useFormContext<T>()
  const inputProps = useGetInputProps({
    required,
    helperText,
    startAdornment,
    endAdornment,
    background,
    shrink,
  })
  const {
    control,
    formState: { errors },
  } = context

  const registerObj = {
    required,
    valueAsNumber: type === 'number',
    maxLength: maxLength ? maxLength : undefined,
    minLength: minLength ? minLength : undefined,
  }

  return fieldName ? (
    <Controller
      control={control}
      name={fieldName}
      rules={registerObj}
      defaultValue={defaultValue as FormFieldValue<T>}
      render={({ field: { value, onChange } }) => {
        // onChange(containerValue || value)
        return (
          <TextField
            className={className}
            disabled={disabled}
            onFocus={onFocus}
            multiline={multiline}
            rows={multiline ? multilineRows : undefined}
            onChange={event => {
              let value: string | number | undefined = event.target.value
              if (type === 'number') {
                value = parseInt(value)
                value = !isNaN(value) ? value : undefined
              }
              onChange(value)
            }}
            error={!!get(errors, fieldName)}
            label={label}
            helperText={helperText || ' '}
            id={fieldName}
            value={defaultValue || value}
            type={type}
            {...inputProps}
          />
        )
      }}
    />
  ) : (
    <TextField
      className={className}
      disabled={disabled}
      onFocus={onFocus}
      multiline={multiline}
      rows={multiline ? multilineRows : undefined}
      label={label}
      helperText={helperText || ' '}
      id={fieldName}
      value={defaultValue}
      type={type}
      {...inputProps}
    />
  )
}

// TODO: visto che potremmo aver bisogno di elementi del form read only che non devono essere
// in binding con i dati del form, bisognerebbe esportare dalla FormWidgets anche i componenti
// solo presentational.
// Per adesso ho usato il workaround (solo sull'?'inputText) del controllare se fieldName è
// thruty o meno per bindarlo con il react-form-hook e ho importato direttamente il componente
// senza passare dal formbuilder.

export default InputText
