import { ApolloError } from '@apollo/client'
import { NetworkError } from '@apollo/client/errors'
import { useCallback } from 'react'
import { useNotificationContext } from '../providers/hooks'
import { NotificationItem } from '../providers/types'

export const useErrorManager = () => {
  const { setNotificationItems, notificationItems } = useNotificationContext()
  const handleError = useCallback(
    (id: NotificationItem['code'], error: ApolloError | NetworkError) => {
      if (!notificationItems.find(item => item.code === id)) {
        setNotificationItems([
          {
            message: `graphqlErrors.${error?.message as string}`,
            code: 'error',
            severity: 'error',
          },
        ])
      }
    },
    [notificationItems, setNotificationItems]
  )

  return handleError
}
