import { loadersHandlerSliceReducer, modalHandlerSliceReducer } from '@core/services/state'
import { sessionStateSliceReducer } from '@core/services/state/session'
import { userSliceReducer } from '@core/services/state/user'
import { combineSlices } from '@reduxjs/toolkit'
import homepageLayoutSliceReducer from './homepageLayout/homepageLayoutSlice'

const rootReducer = combineSlices({
  user: userSliceReducer,
  modal: modalHandlerSliceReducer,
  loaders: loadersHandlerSliceReducer,
  homepageLayout: homepageLayoutSliceReducer,
  session: sessionStateSliceReducer,
})

export default rootReducer
