import { useGraphqlQuery } from 'core/src/hooks/queries/graphqlHooks'
import { useActions } from 'core/src/hooks/useActions'
import { initSession } from 'core/src/services/state'
import { Session } from 'core/src/types/graphqlTypes'
import { useEffect } from 'react'

const useBootstrapDataFetcher = () => {
  const { data, loading } = useGraphqlQuery<{ getSession: Session }>(
    'getSession',
    {},
    { cachePolicy: 'network-only' }
  )
  const actions = useActions({ initSession })

  useEffect(() => {
    if (!loading && data && data.getSession) {
      const initSessionPayload = data.getSession
      actions.initSession(initSessionPayload)
    }
  }, [actions, data, loading])
}

export default useBootstrapDataFetcher
