import { Navigate, useLocation } from 'react-router-dom'
import { useActions } from '../../hooks/useActions'
import useUserLoginHandler from '../../hooks/useUserLoginStatus'
import { setCurrentPage } from '../../services/state'

export interface PrivateRouteProps {
  component: React.ComponentType
  path?: string
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: RouteComponent }) => {
  const { isLoggedIn } = useUserLoginHandler()

  const actions = useActions({ setCurrentPage })
  const location = useLocation()
  actions.setCurrentPage(location)

  if (isLoggedIn) {
    return <RouteComponent />
  }

  return <Navigate to={`/login?redirectUrl=${location.pathname}&isPrivate=true`} />
}
export default PrivateRoute
