import { ConfirmEmail } from '@core/components/ConfirmEmail'
import { NoDataComponent } from '@core/components/NoDataComponent'
import { default as CorePrivateRoute, PrivateRouteProps } from '@core/pages/private/privateRoute'
import { useNavigationContext } from '@core/providers/hooks'
import ForgotPasswordPage from '@privatePages/signin/forgotPassword'
import { ForgotPasswordMessagePage } from '@privatePages/signin/forgotPasswordMessage'
import RegisterPage from '@privatePages/signin/license/Register'
import LicensePage from '@privatePages/signin/license/licensePage'
import LoginPage from '@privatePages/signin/login'
import { SocketProvider } from 'core/src/providers'
import React from 'react'
import { createBrowserRouter, Navigate, Route, RouterProvider, Routes } from 'react-router-dom'
import config from './config'
import useBootstrapDataFetcher from './hooks/useBootstrapDataFetcher'

const Home = React.lazy(() => import('@pages/private/home/overview'))
const HomeReport = React.lazy(() => import('@pages/private/home/Report'))
const HomeStats = React.lazy(() => import('@pages/private/home/Stats'))

const BuyOverview = React.lazy(() => import('@pages/private/buy/overview'))
const CarDetails = React.lazy(() => import('@pages/private/buy/CarDetails'))
const BuySearch = React.lazy(() => import('@pages/private/buy/search'))
const BuyGarage = React.lazy(() => import('@pages/private/buy/garage'))

const AddCar = React.lazy(() => import('@pages/private/sell/addCar'))
const EditCar = React.lazy(() => import('@pages/private/sell/editCar'))
const SellCarsList = React.lazy(() => import('@pages/private/sell/carsList'))
const SellStats = React.lazy(() => import('@pages/private/sell/stats'))
const SellArchive = React.lazy(() => import('@pages/private/sell/archive'))

const TokensList = React.lazy(() => import('@pages/private/admin/tokensList'))
const Users = React.lazy(() => import('@pages/private/admin/usersList'))
const CarsList = React.lazy(() => import('@pages/private/admin/carsList'))
const OrdersList = React.lazy(() => import('@pages/private/admin/ordersList'))

const Checkout = React.lazy(() => import('@pages/private/checkout/checkoutRecap'))
const Thankyou = React.lazy(() => import('@pages/private/checkout/thankYou'))

const User = React.lazy(() => import('@pages/private/user/Index'))

// const Objectives = React.lazy(() => import("./routes/__Objectives"));

const publicRouteList = [
  { path: '/login', component: LoginPage },
  { path: '/forgotPassword', component: ForgotPasswordPage },
  { path: '/forgotPassword/update', component: ForgotPasswordMessagePage },
  { path: '/license', component: LicensePage },
  { path: '/register', component: RegisterPage },
  { path: '/confirm-email', component: ConfirmEmail },
]

const privateRouteList = [
  { path: '/home/overview', component: Home },
  { path: '/home/report', component: HomeReport },
  { path: '/home/statistics', component: HomeStats },
  { path: '/sell/add/step/:stepNumber/:completed?', component: AddCar },
  { path: '/sell/edit/:carId/step/:stepNumber/:completed?', component: EditCar },
  { path: '/sell/cars-list', component: SellCarsList },
  { path: '/sell/statistics', component: SellStats },
  { path: '/sell/archive', component: SellArchive },
  { path: '/buy/overview', component: BuyOverview },
  { path: '/buy/overview/:carId', component: CarDetails },
  { path: '/buy/search', component: BuySearch },
  { path: '/buy/garage', component: BuyGarage },
  { path: '/admin/tokens', component: TokensList },
  { path: '/admin/users', component: Users },
  { path: '/admin/cars', component: CarsList },
  { path: '/admin/orders', component: OrdersList },
  { path: '/user', component: User },
  { path: '/checkout/recap', component: Checkout },
  { path: '/checkout/thank-you', component: Thankyou },
]

const PrivateRoute = (props: PrivateRouteProps) => {
  useBootstrapDataFetcher()

  return <CorePrivateRoute {...props} />
}

const renderRoutes = (which: 'private' | 'public') => {
  const routeList = which === 'private' ? privateRouteList : publicRouteList
  const isPrivate = which === 'private'

  return routeList.map(({ path, component }) => {
    const Component = component
    return (
      <Route
        key={path}
        path={path}
        element={isPrivate ? <PrivateRoute component={Component} /> : <Component />}
      />
    )
  })
}

const Root = () => {
  const { isSupported } = useNavigationContext()

  return isSupported ? (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/home/overview" />} />
        <Route path="/sell/add" element={<Navigate to="/sell/add/step/1" />} />
        {renderRoutes('public')}
      </Routes>

      <SocketProvider url={config.socket.url}>
        <Routes>
          <>{renderRoutes('private')}</>
        </Routes>
      </SocketProvider>
    </>
  ) : (
    <NoDataComponent type="screenSizeTooSmall" />
  )
}

const router = createBrowserRouter([{ path: '*', element: <Root /> }])

const App = () => {
  return <RouterProvider router={router} />
}

export default App
