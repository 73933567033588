import { DateTime, IANAZone } from 'luxon'

const LAMBDA_POLLING = 1
export const ZONE = 'Europe/Rome'

export const convertToSchedulerFormat = (iso: string) => {
  const date = getTimeFromIso(iso)

  if (!date.isValid) {
    throw new Error('Invalid DateTime object')
  }
  const dateWithOffset = date.minus({ minutes: LAMBDA_POLLING })
  const formattedDate = dateWithOffset.toFormat("yyyy-LL-dd'T'HH:mm:ss")
  return `at(${formattedDate})`
}

export const applyTimeZoneToDateTime = (date: DateTime) => {
  const isValidZone = IANAZone.isValidSpecifier(ZONE)
  let res

  if (isValidZone) {
    // siamo certi che entreremo sempre in questo caso, perchè la zone che abbiamo usato è valida, tutti questi
    //controlli servono solo ad aggirare typescript che ipotizzava la possibilità che potesse essere esser null
    res = date.setZone(ZONE)
  } else {
    res = date
  }

  return res
}

export const getCurrentTime = () => {
  const now = DateTime.now()
  return applyTimeZoneToDateTime(now)
}

export const getCurrentIsoTime = () => {
  const now = getCurrentTime()
  return now.toISO()
}

export const getTimeFromIso = (iso: string) => {
  const date = DateTime.fromISO(iso).setZone(ZONE)
  return applyTimeZoneToDateTime(date)
}
