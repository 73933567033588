import { NotificationItem, NotificationSocketCode } from '../../providers/types'

const likedMyCarNotification: NotificationItem = {
  code: 'likedMyCar',
  severity: 'info',
}

const newBidBiddedMyCarNotification: NotificationItem = {
  code: 'newBid_biddedMyCar',
  severity: 'info',
}

const newBidNewHighestNotification: NotificationItem = {
  code: 'newBid_newHighest',
  severity: 'info',
}

const auctionEndedWonNotification: NotificationItem = {
  code: 'auction_ended_won',
  severity: 'info',
}

const auctionEndedLostNotification: NotificationItem = {
  code: 'auction_ended_lost',
  severity: 'error',
}

const newOrderNotification: NotificationItem = {
  code: 'soldMyCar',
  severity: 'info',
}

export const getSoketNotificationByCode = (code: NotificationSocketCode) => {
  const notifications: Partial<Record<NotificationSocketCode, NotificationItem>> = {
    likedMyCar: likedMyCarNotification,
    newBid_biddedMyCar: newBidBiddedMyCarNotification,
    newBid_newHighest: newBidNewHighestNotification,
    soldMyCar: newOrderNotification,
    auction_ended_won: auctionEndedWonNotification,
    auction_ended_lost: auctionEndedLostNotification,
  }

  return notifications && notifications[code]
}
